.App {
  height: 100vh;
  background-color: #000;
  text-align: center;
  color: #ffffff;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.sub-text {
  font-size: 20px;
  color: white;
}

.error {
  font-size: 30px;
  color: red;
}

.reservations-left {
  font-size: 30px;
  color: white;
}

.content-container {
  background-color: #a200d6;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
  padding-top: 150px;
}

.connect-wallet-container img {
  padding-bottom: 20px;
}

.default-button {
  margin-top: 40px;
  padding: 0.75rem 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 35px;
  background-color: #000;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.error-button {
  margin-top: 40px;
  padding: 0.75rem 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 35px;
  background-color: red;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.default-button:disabled {
  background-image: none;
  color: grey;
  cursor: not-allowed;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
